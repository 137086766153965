import copy from 'simple-copy-to-clipboard'

export default function() {
  const btns = document.querySelectorAll('.js-copy-to-clipboard');

  btns.forEach(item=>{
    item.addEventListener('click', function(){
      copy(this.dataset.clipboardText);
      this.ariaLabel = 'Copied!';
      this.classList.add('tooltip');
    })

    item.addEventListener('mouseleave', function(){
      this.classList.remove('tooltip');
    })
  })
}
