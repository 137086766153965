export default function initScrollToTop() {
  const scrollToTopButton = document.querySelector('.js-scroll-to-top');
  const threshold = 500;

  if (!scrollToTopButton) return

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  scrollToTopButton.addEventListener('click', scrollToTop);

  window.addEventListener('scroll', () => {
    if (document.documentElement.scrollTop > threshold || document.body.scrollTop > threshold) {
      scrollToTopButton.classList.add('is-active');
    } else {
      scrollToTopButton.classList.remove('is-active');
    }
  });

}
