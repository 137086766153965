import domReady from '@roots/sage/client/dom-ready';

// import external dependencies
import initOffCanvas from './modules/off-canvas'
import initHeadroom from './modules/headroom'
import {initOurServicesCarousel, initWorkWithCarousel, initTalentsCarousel, initTestimonialCarousel, initInspirationCarousel, initClientsCarousel, initHeroCarousel, initCarouselBlock, initLatestWorksCarousel, initTestimonialCardsCarousel, initInstagramCarousel } from './modules/carousel'
import { videoBlockTweaking } from './modules/blocks'
import { initTrendingArtcileCarousel } from './modules/carousel'
import navStateFix from './modules/navStateFix'
import socialShare from './modules/socialShare'
import copyToClipboard from './modules/copyToClipboard'
import animation from './modules/animation'
import NewsLetterSignUp from './modules/newsletter'
import initMap from './modules/map';
import contactFormSubmit from './modules/contactForm'
import initMediaBox from './modules/mediabox';
import initHoverDir from './modules/hover-dir';
import initScrollToTop from './modules/scroll-to-top';


/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  // Common Pages
  initOffCanvas();
  initHeadroom();
  initHeroCarousel();
  initCarouselBlock();
  videoBlockTweaking();
  initTrendingArtcileCarousel();
  navStateFix();
  initLatestWorksCarousel();
  socialShare();
  copyToClipboard();
  initTestimonialCardsCarousel();
  initInstagramCarousel();
  animation();
  NewsLetterSignUp();
  initInspirationCarousel();
  initClientsCarousel();
  initOurServicesCarousel();
  initWorkWithCarousel();
  initScrollToTop();

  // Contact page
  initMap();
  contactFormSubmit();

  // Homepage
  initMediaBox();

  // What we do page
  initTestimonialCarousel();

  //Who page
  initHoverDir();
  initTalentsCarousel();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);



// Load Events
// jQuery(document).ready(() => routes.loadEvents());
