import $ from 'jquery';

export default function() {
  $('.js-off-canvas-trigger').on('click', function(e) {
    e.preventDefault();
    $('body').toggleClass('is-off-canvas-open');
  })
  $('.js-page-wrap').on('click', function(e){
    if (e.target !== this)
    return;
    $('body').removeClass('is-off-canvas-open');
  });
}
