const hasAudio = (video) => {
  if (typeof video.webkitAudioDecodedByteCount !== 'undefined') {
    // non-zero if video has audio track
    return video.webkitAudioDecodedByteCount > 0;
  }
  else if (typeof video.mozHasAudio !== 'undefined') {
    // true if video has audio track
    return video.mozHasAudio;
  }
  else
    // 'cant tell if video has audio'
    return true;
}

export const videoBlockTweaking = () => {
  const blocks = document.querySelectorAll('.wp-block-video, .wp-block-cover');

  blocks.forEach(block=>{
    const video = block.querySelectorAll('video');
    if(!hasAudio(video[0])) return;
    const audioBtn = document.createElement('div');
    audioBtn.className = 'audio-btn';
    block.appendChild(audioBtn);

    audioBtn.addEventListener('click', function() {
      video[0].muted = !video[0].muted;
      if(video[0].muted) {
        audioBtn.classList.remove('unmuted');
      } else {
        audioBtn.classList.add('unmuted');
      }
    })
  })
}
