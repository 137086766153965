
//set Delay in Seconds
const popupOpenDelay = 30;
const popupCloseDelay = 20;

export default function NewsLetterSignUp() {
  const newsLetters = document.querySelectorAll('.js-newsletter');

  if (!newsLetters.length) {
    return;
  }

  newsLetters.forEach(newsLetter => {
    const form = newsLetter.querySelector('form');
    const submitBtn = form.querySelector('button[type="submit"]');
    const tyEl = newsLetter.querySelector('.newsletter__thanks');
    let msg = tyEl.innerHTML;

    handlePopUp(newsLetter);

    form.addEventListener('submit', e => {
      e.preventDefault();
      submitBtn.disabled = true;
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
      const forename = data.forename;
      const surname = data.surname;
      const email = data.email;

      msg = msg.replace('{{first_name}}', `${forename}`).replace('{{last_name}}', `${surname}`).replace('{{full_name}}', `${forename} ${surname}`).replace('{{email}}', `${email}`);


      fetch('/wp-json/polar/newslatter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(res => {
        res.json();
      }).then(data=>{
        console.log(data);
        tyEl.innerHTML = msg;
        newsLetter.classList.add('newsletter--success');

        if (newsLetter.classList.contains('newsletter--popup')) {
          // store in localStorage
          localStorage.setItem('newsletter-interacted', true);
          // close newsletter popup after 5 secondsß
          setTimeout(() => {
            newsLetter.classList.remove('is-open');
          }, popupCloseDelay * 1000);
        }
      }).catch(err=>console.log(err))
    })
  })
}

const handlePopUp = (newsLetter) => {
  if (newsLetter.classList.contains('newsletter--popup')) {
    const closeBtn = newsLetter.querySelector('.js-newsletter-popup-close');
    closeBtn.addEventListener('click', () => {
      newsLetter.classList.remove('is-open');
      // store in localStorage
      sessionStorage.setItem('newsletter-interacted', true);
    })

    //get localStorage item
    const interacted = localStorage.getItem('newsletter-interacted') || sessionStorage.getItem('newsletter-interacted') ? true : false;

    if (interacted) {
      return;
    }
    setTimeout(() => {
      newsLetter.classList.add('is-open');
    }, popupOpenDelay * 1000);
  }
}
