const navStateFix = () => {
  const items = document.querySelectorAll('.primary-nav .menu-item.active');
  const params = window.location.pathname.split('/').filter(item => item != '');

  if (params[0] !== 'our-work') {
    return;
  }

  items.forEach(item=> {
    if (!item.children[0].href.replace('https://', '').split('/').includes(params[0])) {
      item.classList.remove('active');
    }
  })
}

export default navStateFix;
