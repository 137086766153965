import { Loader } from '@googlemaps/js-api-loader'
const initMap = () => {
  const el = document.querySelectorAll('.map');

  const loader = new Loader({
    apiKey: 'AIzaSyA5FfQeFki6N5H37KSwTyk5sgOtXYpd1YM',
    version: 'weekly',
  });

  el.forEach((item)=>{
    const lat = parseFloat(item.dataset.lat);
    const lng = parseFloat(item.dataset.lng);

    loader.load().then((google) => {
      const latLng = {lat, lng}

      const map = new google.maps.Map(item, {
        center: latLng,
        zoom: 16,
      });

      new google.maps.Marker({
        position: latLng,
        map,
      });
    }).catch(err=>{
      console.log(err);
    });
  })
}

export default initMap;
