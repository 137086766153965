export default function contactFormSubmit () {
        // Get form and thank you message elements
        const contactForm = document.getElementById('contactForm');
        const thankYouMessage = document.getElementById('thankYouMessage');

        // Add submit event listener to the form
        //console.log(contactForm);
        if(!contactForm) return;
        
        contactForm.addEventListener('submit', (event) => {
            // Prevent the default form submission behavior
            event.preventDefault();
    
            // Hide the form
            contactForm.style.display = 'none';
    
            // Display the thank you message
            thankYouMessage.style.display = 'block';
    
            return false;
        });
        
    
}