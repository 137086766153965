import Headroom from 'headroom.js';

export default function() {
  const header = document.querySelector('.js-headroom');
  const innerHeight = header.clientHeight;

  const headroom  = new Headroom(header, {
    offset: {
      up: 0,
      down: innerHeight,
    },
    tolerance : 32,
  });
  headroom.init();
}
